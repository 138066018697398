import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from '../common/dev/log';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Transaction } from './transaction';
import { TransactionService } from './transaction.service';
import { Observable, of } from 'rxjs';
import { startWith, map, tap, take } from 'rxjs/operators';
import { STATES } from '../common/state';
import { Auto } from '../auto';
import { Customer } from '../customer';
import { UniqueNumberValidator, hasNameValidator, objectSelectedValidator } from '../common/validators';
import { __values } from 'tslib';
import { isFakeTouchstartFromScreenReader } from '@angular/cdk/a11y';

@Component({
    selector: "edit-transaction",
    templateUrl: "./edit-transaction.component.html"
})

export class EditTransactionComponent implements OnInit {

    title: string;
    isEdit: boolean;
    isSearch: boolean;
    nextTransactionNumber: string;
    // updatedCustomerList = [];

    getTransactionNumber = () => this.currentTransaction.TransactionNumber;

    form: FormGroup;

    public transactions: Transaction[] = [];
    public states = STATES;
    public currentTransaction: Transaction;

    private _autoNumbers: Auto[] = [];
    private _customers: Customer[] = [];

    filteredTransactions: Observable<Transaction[]>;

    constructor (
        private route: ActivatedRoute,
        private apps: AppService,
        private ts: TransactionService,
        private router: Router
    ) {
        this.currentTransaction = new Transaction();
        this.form = this.createFormGroup();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {
            title: string,
            edit: boolean,
            search: boolean,
            nextTransactionNumber: string,
            currentTransaction: Transaction,
            autoNumbers: Auto[],
            customers: Customer[]
        }) => {
            log.Debug("data: ", data)
            this.title = data.title;
            this.isEdit = data.edit;
            this.isSearch = data.search;
            this.nextTransactionNumber = data.nextTransactionNumber
            this._customers = data.customers;
            this._autoNumbers = data.autoNumbers
            Object.assign(this.currentTransaction, data.currentTransaction);
        })

        this.filteredTransactions = this.form.controls['TransactionNumber'].valueChanges.pipe(
            startWith(''),
            map(value => this._filterTransactions(value))
        );

        if (!this.isEdit && !this.isSearch) {
            // this.updateCustomerList(data.customerNames);
            log.Debug("is new: ", this.nextTransactionNumber)
            this.form.controls['TransactionNumber'].setValue(this.nextTransactionNumber);
            this.form.controls['Transacted'].setValue(new Date());
            this.transactions = []
        } else if ( this.isEdit ) {
            // this.updateCustomerList(data.customerNames);
            this.transactions = []
                
            this.form.patchValue(this.currentTransaction);
            this.form.get('Auto').patchValue(this.currentTransaction.Auto)
            this.form.get('Customer').patchValue(new Customer(this.currentTransaction.Customer))
        } else {
            log.Debug("search")
            this.form.get('TransactionNumber').clearValidators();
            this.form.get('TransactionNumber').clearAsyncValidators();
            this.form.get('TransactionNumber').updateValueAndValidity();

            this.form.get('Transacted').clearValidators();
            this.form.get('Transacted').updateValueAndValidity();

            this.form.get('Auto').clearValidators();
            this.form.get('Auto').updateValueAndValidity();

            this.form.get('Customer').clearValidators();
            this.form.get('Customer').updateValueAndValidity();

            this.form.get('SaleAmount').clearValidators();
            this.form.get('SaleAmount').updateValueAndValidity();

            this.transactions = []
        }
    }

    createFormGroup() {
        return new FormGroup({
            ID: new FormControl(),
            TransactionNumber: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: [UniqueNumberValidator.createValidator({number: this.getTransactionNumber, service: this.ts})]
            }),
            Transacted: new FormControl(null, Validators.required),
            Auto: new FormControl(null, [Validators.required, objectSelectedValidator()]),
            Customer: new FormControl(null, [Validators.required, objectSelectedValidator()]),
            SaleAmount: new FormControl(null, Validators.required),
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    // NA commented out 5/11/23
    // getName(customer: Customer): string {

    //     if(customer.Company) {
    //         return customer.Company;
    //     }

    //     if(customer.LastName) {
    //         if(customer.FirstName) {
    //             return customer.FirstName + ' ' + customer.LastName;
    //         }
    //         return customer.LastName;
    //     }

    //     return '';
    // }

    gotoNew() {
        this.router.navigate(['../../', 'new'], {relativeTo: this.route});
    }

    private _filterTransactions(value: string): Transaction[] {
        log.Debug('value: ', value)
        if(!this.transactions) {
            return null
        }
        log.Debug('return: ', this.transactions.filter(option => option.TransactionNumber.toLowerCase().indexOf(value.toLowerCase()) === 0))
        return this.transactions.filter(option => option.TransactionNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    }

    // NA commented out 5/11/23
    // private _getAutoNumberByID(id: number): string {
    //     if(!this._autoNumbers) {
    //         return ''
    //     }
    //     const index = this._autoNumbers.findIndex( auto => auto.ID === id);
    //     if (index === -1) {
    //         if(this.currentTransaction.Auto.ID == id) {
    //             return this.currentTransaction.Auto.AutoNumber
    //         }
    //         return '';
    //     }
    //     return this._autoNumbers[index].AutoNumber;
    // }

    // private _filterAutos(value: string): Auto[] {
    //     log.Debug('value: ', value)
    //     if(!this._autoNumbers) {
    //         return null
    //     }
    //     log.Debug('return: ', this._autoNumbers.filter(option => option.AutoNumber.toLowerCase().indexOf(value.toLowerCase()) === 0))
    //     return this._autoNumbers.filter(option => option.AutoNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    // }

    // private _getCustomerNameByID(id: number): string {
    //     if(!this._customerNames) {
    //         return ''
    //     }
    //     const index = this._customerNames.findIndex( customer => customer.ID === id);
    //     if (index === -1) {
    //         return '';
    //     }
    //     if( this._customerNames[index].Company ) {
    //         return this._customerNames[index].Company;
    //     }
    //     return this._customerNames[index].FirstName + ' ' + this._customerNames[index].LastName;
    // }

    // private _filterCustomers(value: string): Customer[] {
    //     log.Debug("value: ", value)
    //     if(!this._customerNames) {
    //         return null
    //     }
    //     //let name =
    //     return this._customerNames
    //         .filter(option => (option.FirstName + ' ' + option.LastName).toLowerCase().indexOf(value.toLowerCase()) === 0 || option.Company.toLowerCase().indexOf(value.toLowerCase()) === 0 );
    // }

    // private _filterCustomers(value: string): Customer[] {
    //     log.Debug("value: ", value)
    //     if(!this._customerNumbers) {
    //         return null
    //     }
    //     return this._customerNumbers.filter(option => option.CustomerNumber.toLowerCase().indexOf(value.toLowerCase()) === 0);
    // }

    filterCustomers = (value: string | Customer): Observable<Customer[]> => {
        value = value || 'a';

        if( typeof value != 'string' ) {
            return of([value])
        }

        return of(this._customers.filter(val => val.Name.toUpperCase().indexOf(value.toString().toUpperCase()) !== -1));
    }

    displayCustomers = (customer: Customer): string => {
        // log.Debug("customer in display customer: ", customer)
        return customer ? customer.Name : ''
    }

    filterAutos = (value: string | Auto): Observable<Auto[]> => {
        value = value || '0';

        log.Debug("value in filter autos: ", value)

        if( typeof value != 'string' ) {
            return of([value])
        }

        return of(this._autoNumbers.filter(val => val.AutoNumber.toUpperCase().indexOf(value.toString().toUpperCase()) !== -1));
    }

    displayAutos = (auto: Auto): string => {
        return auto ? auto.AutoNumber : "";
    }

    goToBillOfSale() {
        this.router.navigate(['print', 'bill-of-sale', this.currentTransaction.ID]);
    }

    onSubmit(e): void {

        if( this.isSearch && this.form.dirty ) {
            this.form.setErrors(null)
            this.ts.GetByNumber(this.form.get('TransactionNumber').value).pipe(take(1)).subscribe(transactionID => {
                if (transactionID == -1) {
                    this.apps.Error('Cannot find an auction with that transaction number.');
                    return
                }
                this.router.navigate([transactionID], {relativeTo: this.route});
            });

            return
        }

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form not touched: ", this.form)
            return;
        }

        this.save();
    }

    save() {
        let transaction = new Transaction(this.form.value)
        transaction.AutoID = transaction.Auto.ID
        transaction.CustomerID = transaction.Customer.ID
        this.ts.save(transaction)
        .subscribe((savedTransaction: Transaction) => {
            this.apps.Confirmation('Auction saved.');
            this.form.markAsPristine();

            if( !this.isEdit ) {
                this.currentTransaction = new Transaction(savedTransaction);
                this.transactions.push(this.currentTransaction);
                this.router.navigate(['../', 'edit', savedTransaction.ID], {relativeTo: this.route});
            }

            log.Debug("savedTransaction: ", savedTransaction)

            const index = this.transactions.findIndex( transaction => transaction.ID === this.currentTransaction.ID );
            if (index != -1) {
                Object.assign(this.transactions[index], new Transaction(savedTransaction));
            }
            this.currentTransaction = savedTransaction
            // this.form.patchValue(savedTransaction);
        })
    }

    saveAndPrint() {

        if( !this.form.dirty || this.form.invalid ) {
            log.Debug("form invalid: ", this.form)
            this.apps.Confirmation('Please fill all fields before saving.');
            return;
        }

        let transaction = new Transaction(this.form.value)
        transaction.AutoID = transaction.Auto.ID
        transaction.CustomerID = transaction.Customer.ID
        this.ts.save(transaction)
        .subscribe((savedTransaction: Transaction) => {
            this.apps.Confirmation('Auction saved.');
            this.form.markAsPristine();
            this.currentTransaction = new Transaction(savedTransaction);
            this.transactions.push(this.currentTransaction);
            this.goToBillOfSale();
        })
    }

    delete(e) {
        e.preventDefault();
        e.stopPropagation();
        if (confirm('This auction will be deleted. Are you sure?')) {
            this.ts.delete(this.currentTransaction).subscribe((resp: {Success: boolean}) => {
                if (resp.Success) {
                    this.apps.Confirmation('Auction deleted.');
                    const index = this.transactions.findIndex( transaction => transaction.ID === this.currentTransaction.ID);
                    this.transactions.splice(index, 1);
                    this.router.navigate(['dashboard']);
                }
            })
        }
    }

}
