import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auto, AutoService } from '../auto';
import { log } from '../common/dev/log';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from '../app.service';
import { MONTHS } from '../common/month';
import { Feed, FeedService } from '../feed';

@Component({
    selector: "motion-for-lien-foreclosure",
    templateUrl: "./motion-for-lien-foreclosure.component.html",
    styleUrls: ['./single-print.component.css', './motion-for-lien-foreclosure.component.css']
})

export class MotionForLienForeclosureComponent implements OnInit {

    currentAuto: Auto
    form: FormGroup
    months = MONTHS;
    currentDate: Date;

    fields = {
        CompanyCounty: '',
        DateFiled: new Date(),
        CompanyName: '',
        CompanyAddress: '',
        CompanyCityStateZip: '',
        Mark: '',
        OwnerName:'',
        OwnerAddress: '',
        OwnerCityStateZip: '',
        Lienholder1Name:'',
        Lienholder2Name: '',
        AutoCaseNumber: '',
        AutoYearMakeModel: '',
        AutoVIN: '',
        AutoTagState: '',
        AutoDateIn: new Date(),
        FiledDay: '',
        FiledMonth: '',
        FiledYearLastTwo: '',
        CompanyNotary: '',
        CompanyNotaryExpires: '',
        AutoYear: '',
        AutoMake: '',
        AutoModel: '',
        AutoNumber: '',
    }
    constructor (
        private route: ActivatedRoute,
        private as: AutoService,
        private apps: AppService,
        private FeedService: FeedService,
    ){
        this.form = this.createFormGroup();
    }
    ngOnInit(): void {
        this.route.data.subscribe((data: {
            isBlank: boolean,
            currentAuto: Auto,
            settings: {}
        }) => {

            log.Debug("data: ", data)

            this.currentAuto = data.currentAuto;
            this.currentDate = new Date();

            if(!data.isBlank) {

                this.fields.CompanyCounty = data.settings['Company County'] || '';
                this.fields.CompanyName = data.settings['Company Name'] || '';
                this.fields.CompanyAddress = data.settings['Company Address'] || '';
                this.fields.CompanyCityStateZip = this.getCityStateZip(data.settings['Company City'], data.settings['Company State'], data.settings['Company Zip']) || '';
                this.fields.Mark = 'X';
                this.fields.AutoCaseNumber = this.currentAuto.CaseNumber || '';
                this.fields.AutoYearMakeModel = this.getYearMakeModel(this.currentAuto.AutoYear, this.currentAuto.Make.Name, this.currentAuto.AutoModel.Name) || '';
                this.fields.AutoVIN = this.currentAuto.Vin || '';
                this.fields.AutoTagState = this.getTagTagState(this.currentAuto.Tag, this.currentAuto.State) || '';
                this.fields.AutoDateIn = this.currentAuto.DateIn;
                this.fields.CompanyNotary = data.settings['Form Agent'] || '';
                this.fields.CompanyNotaryExpires = '';
                if( Boolean(this.currentAuto.Owners[0]) ) {
                    this.fields.OwnerName = this.getName(this.currentAuto.Owners[0].Company, this.currentAuto.Owners[0].FirstName, this.currentAuto.Owners[0].LastName) || '';
                    this.fields.OwnerAddress = this.getAddress(this.currentAuto.Owners[0].Address1, this.currentAuto.Owners[0].Address2) || '';
                    this.fields.OwnerCityStateZip = this.getCityStateZip(this.currentAuto.Owners[0].City, this.currentAuto.Owners[0].State, this.currentAuto.Owners[0].Zip.toString()) || '';
                }
                if( Boolean(this.currentAuto.Lienholders[0]) ) {
                    this.fields.Lienholder1Name = this.getName(this.currentAuto.Lienholders[0].Company, this.currentAuto.Lienholders[0].FirstName, this.currentAuto.Lienholders[0].LastName) || '';
                }
                if( Boolean(this.currentAuto.Lienholders[1]) ) {
                    this.fields.Lienholder2Name = this.getName(this.currentAuto.Lienholders[1].Company, this.currentAuto.Lienholders[1].FirstName, this.currentAuto.Lienholders[1].LastName) || '';
                }
                this.fields.AutoYear = this.currentAuto.AutoYear || '';
                this.fields.AutoMake = this.currentAuto.Make.Name || '';
                this.fields.AutoModel = this.currentAuto.AutoModel.Name || '';
                this.fields.AutoNumber = this.currentAuto.AutoNumber || '';

            }
        });
    }

    createFormGroup() {
        return new FormGroup({
            DateFiled: new FormControl(),
            DatePetitionFiled: new FormControl(),
            LienAmount: new FormControl(),
            LienDate: new FormControl(),
        })
    }

    getErrorMessage(field: FormControl): string {
        return this.apps.ErrorMessages(field)
    }

    getYearMakeModel(year: string, make: string, model: string): string {
        if( !this.empty(year) ) {
            return year + ' ' + make + ' ' + model;
        }
        return make + ' ' + model;
    }

    getTagTagState(tag: string, state: string): string {

        let tagValid = !this.empty(tag);
        let stateValid = !this.empty(state);

        if( tagValid && stateValid ) {
            return tag + ' ' + state;
        } else if( !tagValid && stateValid ) {
            return state;
        } else if ( tagValid && !stateValid ) {
            return tag;
        }

        return ''
    }

    getCityStateZip(city: string, state: string, zip: string): string {

        let cityValid = !this.empty(city);
        let stateValid = !this.empty(state);
        let zipValid = !this.empty(zip);

        if( cityValid && stateValid && zipValid ) {
            return city + ', ' + state + ' ' + zip;
        } else if( cityValid && stateValid && !zipValid ) {
            return city + ', ' + state;
        } else if( !cityValid && stateValid && zipValid ) {
            return state + ' ' + zip;
        } else if(!cityValid && stateValid && !zipValid ) {
            return state;
        } else if( cityValid && !stateValid && zipValid ) {
            return city + ' ' + zip;
        } else if( cityValid && !stateValid &&  !zipValid ) {
            return city;
        } else if( !cityValid && !stateValid && zipValid ) {
            return zip;
        }

        return '';

    }

    getName(company: string, first: string, last: string): string {
        const companyValid = !this.empty(company)
        const firstValid = !this.empty(first)
        const lastValid = !this.empty(last)

        if( companyValid ) {
            return company
        }

        if( firstValid && lastValid ) {
            return first + ' ' + last
        } else if( !firstValid && lastValid ) {
            return last
        } else {
            return first
        }

        return ''
    }

    getAddress( add1: string, add2: string ): string {
        const add1Valid = !this.empty(add1);
        const add2Valid = !this.empty(add2);

        if( add1Valid && add2Valid ) {
            return add1 + ' ' + add2;
        } else if( add1Valid && !add2Valid ) {
            return add1;
        } else {
            return add2;
        }

        return ''
    }

    getDayFromDate(date: Date): string {
        if( !Boolean(date) ) {
            return ''
        }
        const d = new Date(date)
        return d.getDay().toString();
    }

    getMonthFromDate(date: Date): string {
        if( !Boolean(date) ) {
            return ''
        }
        const d = new Date(date)
        log.Debug("month: ", d.getMonth())
        return this.months[d.getMonth()];
    }

    getYearLastTwoFromDate(date: Date): string {
        if( !Boolean(date) ) {
            return ''
        }
        const d = new Date(date)
        log.Debug("full year last two: ", d.getFullYear().toString().substr(-2))
        return d.getFullYear().toString().substr(-2);
    }

    empty(str: string): boolean {
        if( '' != str && null != str && undefined != str ) {
            return false;
        }
        return true;
    }

    print(): void {
        //Change to note maybe?
        this.FeedService.save(new Feed({ AutoID: this.currentAuto.ID, Description: "Motion for Lien Foreclosure Printed", Created: this.currentDate })).subscribe(
            resp => log.Debug("resp: ", resp)
        )
        window.print();
    }
}
